import * as types from "../actions/actionTypes";

const initialState = {
  lendingTypes: [],
  loanTypes: [],
  lendingList: [],
  maxTermTypes: [],
  minDscrTypes: [],
  maxInterestOnlyTypes: [],
  prePaymentPenaltyTypes: [],
  maxInitialTermTypes: [],
  maxPermAfterConstructionTypes: [],
  maxAmortizationTypes: [],
  simpleRateSheetTypes: [],
  propTypesList: [],
  stateList: [],
  recourseTypes: [],
  lendingItem: {},
  noLendingItem: false,
  noLendingList: false,
  successToast: false,
  loading: false,
  message: "",
  searchResults: [],
  currentBank :  {label : "Select a Lender", value : 0},
  currentBanker : {label : "Select a Lender Contact", value : 0},
  banksBankers : [],
};

export default function lendingCriteriaReducer(state = initialState, action) {
  switch (action.type) {
    case types.LOADING_PARAM_LIST:
      return Object.assign({}, state, {
        lendingList: [],
        noLendingList: false,
        loading: true,
      });
    case types.NO_PARAM_LIST:
      return Object.assign({}, state, {
        lendingList: [],
        noLendingList: true,
        loading: false,
      });
    case types.LOAD_PARAM_LIST:
      return Object.assign({}, state, {
        lendingList: action.lendingList,
        loading: false,
      });

    case types.LOADING_PARAM_BY_ID:
      return Object.assign({}, state, {
        loading: true,
        lendingItem: {},
        noLendingItem: false,
      });
    case types.NO_PARAM_BY_ID:
      return Object.assign({}, state, {
        lendingItem: {},
        noLendingItem: true,
        loading: false,
      });
    case types.LOAD_PARAM_BY_ID:
      return Object.assign({}, state, {
        lendingItem: action.lendingItem,
        loading: false,
      });

    case types.LOAD_LENDING_TYPES:
      return Object.assign({}, state, { lendingTypes: action.lendingTypes });
    case types.LOAD_LOAN_TYPES:
      return Object.assign({}, state, { loanTypes: action.lendingTypes });
    case types.LOAD_MAX_TERM_TYPES:
      return Object.assign({}, state, { maxTermTypes: action.maxTermTypes });
    case types.LOAD_MIN_DSCR_TYPES:
      return Object.assign({}, state, { minDscrTypes: action.minDscrTypes });
    case types.LOAD_MAX_INTEREST_ONLY_TYPES:
      return Object.assign({}, state, { maxInterestOnlyTypes: action.maxInterestOnlyTypes });
    case types.LOAD_PREPAYMENT_PENALTY_TYPES:
      return Object.assign({}, state, { prePaymentPenaltyTypes: action.prePaymentPenaltyTypes });
    case types.LOAD_MAX_INITIAL_TERM_TYPES:
      return Object.assign({}, state, { maxInitialTermTypes: action.maxInitialTermTypes });
    case types.LOAD_MAX_PERM_AFTER_CONSTRUCTION_TYPES:
      return Object.assign({}, state, { maxPermAfterConstructionTypes: action.maxPermAfterConstructionTypes });
    case types.LOAD_MAX_AMORTIZATION_TYPES:
      return Object.assign({}, state, { maxAmortizationTypes: action.maxAmortizationTypes });
    case types.LOAD_SIMPLE_RATE_SHEET_TYPES:
      return Object.assign({}, state, { simpleRateSheetTypes: action.simpleRateSheetTypes });
    case types.LOAD_PROP_TYPES:
      return Object.assign({}, state, { propTypesList: action.propTypesList });
    case types.LOAD_STATE_LIST:
      return Object.assign({}, state, { stateList: action.stateList });
    case types.LOAD_RECOURSE_TYPES:
      return Object.assign({}, state, { recourseTypes: action.recourseTypes });
    case types.ADD_LENDING_CRITERIA_SUCCESS:
      return Object.assign({}, state, {
        successToast: true,
        message: action.message,
      });
    case types.UPDATE_LENDING_CRITERIA_SUCCESS:
      return Object.assign({}, state, {
        successToast: true,
        message: action.message,
      });
    case types.CLOSE_LENDING_CRITERIA_SUCCESS:
      return Object.assign({}, state, { successToast: false, message: "" });

    case types.GET_CITY_STATES_LIST:
      return Object.assign({}, state, { searchResults: action.searchResults });
    default:
      return state;
    case types.SET_CURRENT_BANK:
      return Object.assign({},state, {
        currentBank : action.currentBank
      });
    case types.SET_CURRENT_BANKER:
      return Object.assign({},state, {
        currentBanker : action.currentBanker
      });
    case types.SET_BANKS_BANKERS:
      return Object.assign({},state, {
        banksBankers : action.banksBankers
      });
    case types.SHOW_TOAST_ERROR:
      return Object.assign({}, state, {
        successToast: true,
        message: action.message,
      });
    case types.LOADING_SPINNER:
      return Object.assign({}, state, {
        loading: action.message,
      });
  }
}
